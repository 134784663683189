import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router'
import { useStore } from '@/store'

// 定义路由 meta 类型
interface RouteMeta {
  title: string
  pathName?: string
  parent?: string | number
}

// 扩展 RouteRecordRaw 以包含自定义的 meta 类型
interface CustomRouteRecordRaw extends Omit<RouteRecordRaw, 'children' | 'meta'> {
  meta?: RouteMeta
  children?: CustomRouteRecordRaw[]
}

// 定义路由映射关系类型
interface RouteKeepAliveMap {
  [key: string]: string
}

const routes: Array<CustomRouteRecordRaw> = [
  {
    path: '/login',
    redirect: '/',
  },
  // 404页面
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/error/index.vue'),
    meta: { title: '404' },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: { title: '首页' },
    redirect: '/incoming',
    children: [
      {
        path: '/rolePermission',
        name: 'rolePermission',
        component: () => import('@/views/permissionManage/rolePermission.vue'),
        meta: { title: '角色权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/userPermission',
        name: 'userPermission',
        component: () => import('@/views/permissionManage/userPermission.vue'),
        meta: { title: '用户权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/appPermission',
        name: 'appPermission',
        component: () => import('@/views/permissionManage/appPermission.vue'),
        meta: { title: '应用权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/incoming',
        name: 'incoming',
        component: () => import('@/views/revenueKanbanManage/index.vue'),
        meta: { title: '收入报表', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor/index.vue'),
        meta: { title: '监控看板', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/accountManage',
        name: 'accountManage',
        component: () => import('@/views/payManage/accountManage/index.vue'),
        meta: { title: '主体管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/newAccountManage',
        name: 'newAccountManage',
        component: () => import('@/views/payManage/newAccountManage/index.vue'),
        meta: { title: '账号管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/appManage',
        name: 'appManage',
        component: () => import('@/views/payManage/appManage/index.vue'),
        meta: { title: '产品管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/goodsSetting',
        name: 'goodsSetting',
        component: () => import('@/views/goodsManage/goodsSetting/index.vue'),
        meta: { title: '商品配置', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsSettingDetail',
        name: 'goodsSettingDetail',
        component: () => import('@/views/goodsManage/goodsSetting/detail.vue'),
        meta: { title: '商品配置详情', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsJudge',
        name: 'goodsJudge',
        component: () => import('@/views/goodsManage/goodsJudge/index.vue'),
        meta: { title: '商品审核', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsInfo',
        name: 'goodsInfo',
        component: () => import('@/views/goodsManage/goodsInfo/index.vue'),
        meta: { title: '商品信息', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/logManage',
        name: 'logManage',
        component: () => import('@/views/logManage/index.vue'),
        meta: { title: '操作日志', pathName: '日志管理', parent: 'logManage' },
      },
      {
        path: '/oldOrderList',
        name: 'oldOrderList',
        component: () => import('@/views/orderManage/orderList/index.vue'),
        meta: { title: '订单查询', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/orderList',
        name: 'orderList',
        component: () => import('@/views/orderManage/newOrderList/newOrderIndex.vue'),
        meta: { title: '订单查询', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/refundList',
        name: 'refundList',
        component: () => import('@/views/orderManage/refundOrder/refundOrderIndex.vue'),
        meta: { title: '退款审批', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/duplicateList',
        name: 'duplicateList',
        component: () => import('@/views/orderManage/duplicateOrder/duplicateOrderIndex.vue'),
        meta: { title: '复送订单', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/blackList',
        name: 'blackList',
        component: () => import('@/views/orderManage/blackList/index.vue'),
        meta: { title: '黑名单配置', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/propsGivenAwayList',
        name: 'propsGivenAwayList',
        component: () => import('@/views/propsGivenAway/index.vue'),
        meta: { title: '道具赠送', pathName: '道具赠送', parent: 'propsGivenAwayList' },
      },
      {
        path: '/paidManage',
        name: 'paidManage',
        component: () => import('@/views/paidDownloads/paidManage/index.vue'),
        meta: { title: '付费产品管理', pathName: '付费下载', parent: 'paidDownloadsManage' },
      },
      {
        path: '/paidKanban',
        name: 'paidKanban',
        component: () => import('@/views/paidDownloads/paidKanban/index.vue'),
        meta: { title: '付费下载看板', pathName: '付费下载', parent: 'paidDownloadsManage' },
      },
      {
        path: '/systemNotification',
        name: 'systemNotification',
        component: () => import('@/views/systemNotification/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: 'logManage' },
      },
      {
        path: '/systemNotificationDetail',
        name: 'systemNotificationDetail',
        component: () => import('@/views/systemNotification/systemNotificationDetail/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: 'logManage' },
      },
      {
        path: '/systemNotification/notificationList',
        name: 'notificationList',
        component: () => import('@/views/systemNotification/notificationList/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: '2' },
      },
      {
        path: '/paidEventBillboard',
        name: 'paidEventBillboard',
        component: () => import('@/views/paidEventBillboard/index.vue'),
        meta: { title: '付费事件看板', pathName: '付费事件看板', parent: 'revenueKanban' },
      },
      {
        path: '/whitelistManage',
        name: 'whitelistManage',
        component: () => import('@/views/payManage/whitelistManage/index.vue'),
        meta: { title: '验单白名单', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/refundApproval',
        name: 'refundApproval',
        component: () => import('@/views/orderManage/refundApproval/index.vue'),
        meta: { title: '退款审批', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/promotionCodeList',
        name: 'promotionCodeList',
        component: () => import('@/views/promotionCode/list/index.vue'),
        meta: { title: '推广码列表', pathName: '推广码管理', parent: 'promotionCodeList' },
      },
      {
        path: '/promotionCodeAdd',
        name: 'promotionCodeAdd',
        component: () => import('@/views/promotionCode/add/index.vue'),
        meta: { title: '新增推广码', pathName: '推广码管理', parent: 'promotionCodeList' },
      },
      {
        path: '/thirdPartyBill',
        name: 'thirdPartyBill',
        component: () => import('@/views/thirdPartyBill/index.vue'),
        meta: { title: '产品结算', pathName: '结算管理', parent: 'settlementManage' },
      },
      {
        path: '/thirdPartyBillDetail',
        name: 'thirdPartyBillDetail',
        component: () => import('@/views/thirdPartyBill/thirdPartyDetail/index.vue'),
        meta: { title: '产品结算', pathName: '结算管理', parent: 'settlementManage' },
      },
      {
        path: '/financialSettlement',
        name: 'financialSettlement',
        component: () => import('@/views/settlementManagement/financialSettlement/index.vue'),
        meta: { title: '财务结算', pathName: '结算管理', parent: 'settlementManage' },
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/home/logoutPage.vue'),
    meta: { title: '登出' },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/index.vue'),
    meta: { title: '错误' },
  },
  {
    path: '/accountManageNew',
    name: 'accountManageNew',
    component: () => import('@/views/accountManageNew/index.vue'),
    meta: { title: '主体管理' },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as RouteRecordRaw[],
})

// 路由守卫类型定义
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const routeKeepAliveMap: RouteKeepAliveMap = {
    goodsSetting: 'goodsSettingDetail',
    goodsJudge: 'goodsSettingDetail',
    thirdPartyBill: 'thirdPartyBillDetail',
    promotionCodeList: 'promotionCodeAdd',
  }

  const rtStore = useStore()
  //   rtStore.setCachedName(['goodsJudge'])

  if (from.name && to.name) {
    const fromName = from.name.toString()
    const toName = to.name.toString()

    if (routeKeepAliveMap[fromName]?.includes(toName)) {
      rtStore.setCachedName([fromName])
    } else if (routeKeepAliveMap[toName]?.includes(fromName)) {
      rtStore.setCachedName([toName])
    } else {
      rtStore.setCachedName([])
    }
  }

  next()
})

export default router

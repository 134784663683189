/*
 * @Author: fangheng fangheng@wedobest.com.cn
 * @Date: 2023-07-03 17:15:48
 * @LastEditors: fangheng fangheng@wedobest.com.cn
 * @LastEditTime: 2023-07-19 17:28:23
 * @FilePath: \purchase-web\src\api\payManage\payManage.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * Create by zhuzhaoqing
 * Description: permission
 * Date: 2023/3/31 16:05
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from '../request'

// 产品维度查询接口
// export const getProduct = () => request('payAdminServer/appManage/product', null, 'GET')
// 包名维度查询接口
// export const getPkg = () => request('payAdminServer/appManage/pkg', null, 'GET')
// 工作室维度查询接口
export const getStudio = () => request('payAdminServer/appManage/studio', null, 'GET')
// 实体维度查询接口
export const getMch = () => request('payAdminServer/appManage/mch', null, 'GET')
// 应用管理页面---支付类型查询接口
export const getPayChannelMore = () => request('payAdminServer/appManage/payChannel', null, 'GET')

// 列表查询
export const getQueryList = (data: any) => request('payAdminServer/appManage/list', null, 'POST_JSON', data)
// 根据dbtId 查询单条
export const getQuery = (data: any) => request('payAdminServer/appManage/get', data, 'GET')
// 查询主体配置，新增的时候用的
export const getQueryFuzzy = (data: any) => request('payAdminServer/appManage/dbtId', data, 'GET')
// 新增主体配置
export const postSave = (data: any) => request('payAdminServer/appManage/save', null, 'POST_JSON', data)
// 修改主体配置
export const postEdit = (data: any) => request('payAdminServer/appManage/edit', null, 'POST_JSON', data)
// 根据应用配置查询支付配置
export const queryPayDetail = (data: any) => request('payAdminServer/appManage/query', data, 'GET')
// 根据应用配置修改支付配置
export const updateByMchId = (data: any) => request('payAdminServer/appManage/update', null, 'POST_JSON', data)
// 查询支付配置
export const queryChannelConfig = (data: any) =>
  request('payAdminServer/app/channelConfig/query', null, 'POST_JSON', data)
// 查询应用列表
export const queryAppList = (data: any) => request('payAdminServer/app/list', null, 'POST_JSON', data)
// 查询公用配置
export const queryCommonConfig = (data: any) =>
  request('payAdminServer/app/commonConfig/query', null, 'POST_JSON', data)
// 编辑公用配置
export const saveCommonConfig = (data: any) => request('payAdminServer/app/commonConfig/save', null, 'POST_JSON', data)
// 查询应用信息
export const queryAppInfo = (data: any) => request('payAdminServer/app/dbtId', null, 'POST_JSON', data)
// 保存支付配置
export const saveChannelConfig = (data: any) =>
  request('payAdminServer/app/channelConfig/save', null, 'POST_JSON', data)
// 保存应用
export const saveAdd = (data: any) => request('payAdminServer/app/add', null, 'POST_JSON', data)

import { ref, nextTick, onMounted } from 'vue'
import _ from 'lodash'

/**
 * 自定义复选框Hook
 * @param {Function} emit - 用于向父组件发送事件的函数
 * @param {number} type - 复选框类型：1-表头(默认)，2-导出
 * @param {Array} list - 复选框列表数据
 * @returns {Object} 返回复选框相关的状态和方法
 */
export default function useCheckbox(emit, type = 1, list) {
  // 显示的选中项列表
  const showCheckList = ref([])
  // 复选框列表数据（深拷贝防止互相影响）
  const checkboxList = ref(_.cloneDeep(list))
  // 下拉框显示状态变化处理函数
  const visibleChange = () => {
    // 预留扩展
  }
  // 是否全选
  const isCheckAll = ref(false)
  // 是否半选状态
  const isIndeterminate = ref(false)

  onMounted(() => {
    if (localStorage.getItem('incomingFilter')) {
      checkboxList.value = JSON.parse(localStorage.getItem('incomingFilter') || '[]')
    } else {
      // 初始化复选框状态
      checkboxList.value.map((item) => {
        if (item.value.length === item.children.length) {
          // 全部选中
          item.checkAll = true
          item.indeterminate = false
        } else if (item.value.length === 0) {
          // 全部未选中
          item.checkAll = false
          item.indeterminate = false
        } else {
          // 部分选中状态保持不变
          item.checkAll = false
          item.indeterminate = true
        }
      })
    }
    getShowList(true)
  })

  /**
   * 全选切换处理
   * @param {Event} e - 事件对象
   * @param {Object} group - 当前操作的分组
   */
  const onCheckAllChange = (e, group) => {
    console.log(e, group)
    if (!e.target.checked) {
      group.checkAll = false
      group.indeterminate = false
      group.value = []
    } else {
      group.checkAll = true
      group.indeterminate = false
      group.value = group.children.map((item) => item.key)
    }
    nextTick(() => {
      getShowList(e.target.checked)
    })
  }

  /**
   * 获取并更新显示的选中列表
   * @param {boolean} tag - 是否是全选操作
   */
  const getShowList = (tag) => {
    const temp = []
    checkboxList.value.forEach((item) => {
      item.value.forEach((checkOption) => {
        item.children.forEach((showOption) => {
          if (showOption.key === checkOption) {
            temp.push(showOption.title)
          }
        })
      })
    })
    showCheckList.value = temp

    // 更新全选和半选状态
    if (!tag) {
      isCheckAll.value = false
      isIndeterminate.value = temp.length > 0
    } else {
      let totalCount = 0
      checkboxList.value.forEach((item) => {
        totalCount += item.children.length
      })
      isCheckAll.value = showCheckList.value.length === totalCount
      isIndeterminate.value = !isCheckAll.value && showCheckList.value.length > 0
    }

    localStorage.setItem('incomingFilter', JSON.stringify(checkboxList.value))

    // 触发父组件更新
    emit('getColumn', { list: checkboxList.value, type })
  }

  /**
   * 单个复选框变化处理
   * @param {Event} e - 事件对象
   * @param {Object} group - 当前操作的分组
   */
  const handleChange = (e, group) => {
    console.log(e.target.checked, group)
    if (group.children.length === group.value.length && e.target.checked) {
      group.indeterminate = false
      group.checkAll = true
    } else if (group.value.length === 1 && !e.target.checked) {
      group.indeterminate = false
      group.checkAll = false
    } else if (group.value.length === group.children.length - 1 && e.target.checked) {
      group.indeterminate = false
      group.checkAll = true
    } else if (group.value.length === group.children.length && !e.target.checked) {
      group.checkAll = false
      group.indeterminate = true
    } else {
      group.checkAll = false
      group.indeterminate = true
    }

    nextTick(() => {
      getShowList(e.target.checked)
    })
  }

  /**
   * 处理顶部全选操作
   * @param {Event} e - 事件对象
   */
  const handleAll = (e) => {
    const arr = [
      {
        title: '默认',
        value: [
          'usdSales',
          'usdRevenue',
          'usdAvgRevenueBuyer',
          'usdAvgRevenue',
          'cnySales',
          'cnyRevenue',
          'cnyAvgRevenueBuyer',
          'cnyAvgRevenue',
          'orderCount',
          'buyerCount',
          'avgBuyer',
        ],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '销售额(USD)',
            dataIndex: 'usdSales',
            key: 'usdSales',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '总收入(USD)',
            dataIndex: 'usdRevenue',
            key: 'usdRevenue',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '付费人均付费(USD)',
            dataIndex: 'usdAvgRevenueBuyer',
            key: 'usdAvgRevenueBuyer',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '人均客单价(USD)',
            dataIndex: 'usdAvgRevenue',
            key: 'usdAvgRevenue',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '销售额(CNY)',
            dataIndex: 'cnySales',
            key: 'cnySales',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '总收入(CNY)',
            dataIndex: 'cnyRevenue',
            key: 'cnyRevenue',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '付费人均付费(CNY)',
            dataIndex: 'cnyAvgRevenueBuyer',
            key: 'cnyAvgRevenueBuyer',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '人均客单价(CNY)',
            dataIndex: 'cnyAvgRevenue',
            key: 'cnyAvgRevenue',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '总订单',
            dataIndex: 'orderCount',
            key: 'orderCount',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '付费用户数',
            dataIndex: 'buyerCount',
            key: 'buyerCount',
            sorter: true,
            width: 100,
            checked: false,
          },
          {
            title: '付费人均付费次数',
            dataIndex: 'avgBuyer',
            key: 'avgBuyer',
            sorter: true,
            width: 100,
            checked: false,
          },
        ],
      },
      {
        title: '新用户',
        value: ['firstBuyerCount', 'firstBuyerRatio'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '首次付费用户',
            dataIndex: 'firstBuyerCount',
            key: 'firstBuyerCount',
            sorter: true,
            width: 140,
            checked: false,
          },
          {
            title: '首次付费占比',
            dataIndex: 'firstBuyerRatio',
            key: 'firstBuyerRatio',
            sorter: true,
            width: 140,
            checked: false,
          },
        ],
      },
      {
        title: '退费',
        value: ['refundAmount', 'refundOrderCount'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '退费金额',
            dataIndex: 'refundAmount',
            key: 'refundAmount',
            sorter: true,
            width: 140,
            checked: false,
          },
          {
            title: '退费订单数',
            dataIndex: 'refundOrderCount',
            key: 'refundOrderCount',
            sorter: true,
            width: 140,
            checked: false,
          },
        ],
      },
    ]
    isCheckAll.value = e.target.checked
    if (e.target.checked) {
      isIndeterminate.value = false
      const temp = []
      arr.forEach((item) => {
        item.checkAll = true
        item.indeterminate = false
        item.children.forEach((showOption) => {
          temp.push(showOption.title)
        })
      })
      showCheckList.value = temp
      checkboxList.value = arr
      localStorage.setItem('incomingFilter', JSON.stringify(checkboxList.value))
      emit('getColumn', { list: checkboxList.value, type })
    } else {
      isIndeterminate.value = false
      showCheckList.value = []
      checkboxList.value.forEach((item) => {
        item.value = []
        item.checkAll = false
        item.indeterminate = false
      })
      localStorage.setItem('incomingFilter', JSON.stringify(checkboxList.value))
      emit('getColumn', { list: checkboxList.value, type })
    }
  }

  return {
    showCheckList,
    isCheckAll,
    isIndeterminate,
    checkboxList,
    visibleChange,
    onCheckAllChange,
    getShowList,
    handleChange,
    handleAll,
  }
}

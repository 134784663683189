// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 账单列表查询请求参数类型
export interface AccountBillListParams {
  startMonthDate: string // 结算月开始时间 yyyy-MM
  endMonthDate: string // 结算月结束时间 yyyy-MM
  accountIdList: number[] // 账号id列表
  payChnl: string // 支付渠道
  pageNum?: number
  pageSize?: number
}

// 账单列表项类型
export interface AccountBillItem {
  id: string // 主键id
  pay_chnl: string // 支付渠道
  dateTime: string // 结算月份 yyyy-MM
  accountName: string // 账号名称
  currency: string // 结算币种
  collectionSubject: string // 收款主体
  receivingBank: string // 收款银行
  bankCardFourDigits: string // 银行卡后四位
  estimatedRevenue: string // 预估收入
  expectedReceiptTime: string // 预计收款时间
  actualInvoiceAmount: string // 实际账单收入
  actualReceivedAmount: string // 实际回款金额
  actualReceiptTime: string // 实际收款时间
  receivedStatus: number // 回款状态
  receivedResCode: number // 收款备注码
  receivedResMsg: string // 收款备注信息
}

// 账单更新请求参数类型
export interface AccountBillUpdateParams {
  id: string // 主键id
  expectedReceiptTime?: string // 预估收款时间
  actualReceivedAmount?: string // 实际回款金额
  actualReceiptTime?: string // 实际收款时间
  receivedStatus?: number // 回款状态
  receivedResCode?: number // 收款备注码
  receivedResMsg?: string // 收款备注信息
}

// 任务添加请求参数类型
export interface TaskAddParams {
  list: Array<{
    reqParams: {
      currency: string // 货币
      accountId: number
      fileUrlList: string[] // 文件URL列表
    }
    taskType: number
    taskName: string
  }>
}

// 产品账单列表项类型
export interface ProductBillItem {
  amount: string
  appName: string
  billType: string
  commissionAmount: string
  currency: string
  dateTime: string
  dbtId: string
  orderAmount: string
  payChnl: string
  payChnlName: string
  refundAmount: string
  refundOrderAmount: string
  revenue: string
  tax: string
  billStatus: number // 结算状态
}

// 账号映射关系返回类型
export interface CurrencyAccountMapping {
  currency: string
  accountList: Array<{
    accountName: string
    accountId: number
  }>
}

// 获取账号列表参数类型
export interface GetAccountListParams {
  accountType: 1 | 2 // 1: 苹果, 2: 谷歌
}

// 账单列表查询
export const getAccountBillList = (data: AccountBillListParams) =>
  request('/payAdminServer/account/bill/list', null, 'POST_JSON', data)

// 账单更新
export const updateAccountBill = (data: AccountBillUpdateParams) =>
  request('/payAdminServer/account/bill/update', null, 'POST_JSON', data)

// 添加任务
export const addTask = (data: TaskAddParams) => request('/payAdminServer/bill/task/add', null, 'POST_JSON', data)

// 导出账单
export const exportAccountBill = (data: AccountBillListParams) =>
  request('/payAdminServer/account/bill/export', null, 'POST_DOWNLOAD', data)

// 获取产品账单列表
export const getProductBillList = (data: any) => request('/payAdminServer/bill/getBillList', null, 'POST_JSON', data)

// 获取货币和账号映射关系
export const getCurrencyAccountList = (data: any) =>
  request(`/payAdminServer/project/account/getCurrency2AccountList`, null, 'POST_JSON', data)

// 获取账号列表
export const getAccountList = (params: GetAccountListParams) =>
  request(`/payAdminServer/project/account/getAccountList`, null, 'POST_JSON', params)

import "core-js/modules/esnext.iterator.map.js";
/**
 * Create by zhuzhaoqing
 * Description: useUser
 * Date: 2023/3/31 16:43
 **/
import { getAllPerms, queryAllApps } from '@/api/permission';
import { useStore } from '@/store';
export const useUser = () => {
  const allPerList = ref(),
    allApps = ref([]);
  const getAllPermission = async () => {
      try {
        const {
          code,
          permList
        } = await getAllPerms();
        if (code === '0000') {
          allPerList.value = permList;
        }
      } catch (e) {}
    },
    getAllApp = async () => {
      var _JSON$parse;
      if (!((_JSON$parse = JSON.parse(useStore().userInfo)) !== null && _JSON$parse !== void 0 && _JSON$parse.id)) return;
      try {
        var _JSON$parse2, _JSON$parse3;
        const {
          code,
          data
        } = await queryAllApps({
          userId: (_JSON$parse2 = JSON.parse(useStore().userInfo)) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.id,
          roleList: (_JSON$parse3 = JSON.parse(useStore().userInfo)) === null || _JSON$parse3 === void 0 || (_JSON$parse3 = _JSON$parse3.roles) === null || _JSON$parse3 === void 0 ? void 0 : _JSON$parse3.toString()
        });
        if (code === '0') {
          allApps.value = data === null || data === void 0 ? void 0 : data.map(item => {
            return {
              name: item.dbtName,
              id: item.dbtid
            };
          });
        }
      } catch (e) {}
    };
  onMounted(async () => {
    await getAllPermission();
    await getAllApp();
  });
  return {
    allPerList,
    allApps
  };
};